import { createApp, reactive } from 'vue';
import { createGtag } from 'vue-gtag';
import apiClient from '@/apiClient';
import router from '@/plugins/router';

import App from '@/App.vue';

/* Global style */
import '../css/styles.css';

const gtag = createGtag({
  tagId: 'G-YHV1QYNC4F',
  config: {
    params: {
      anonymize_ip: true,
    },
  },
  pageTracker: {
    router,
    useScreenview: true,
    exclude: [
      'login',
      'admin-login',
      'password',
      'password-reset',
      'admin',
    ],
  },
  appName: 'RRS CIT',
});

const app = createApp(App)
  .use(router)
  .use(gtag);

const globals = app.config.globalProperties;
globals.apiClient = apiClient;
globals.settings = {
  roles: reactive([]),
  origins: reactive([]),
  defects: reactive([]),
};

router.isReady().then(() => {
  app.mount('#app');
});
